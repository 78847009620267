import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
        {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
        },
        {
          text: vm.$t('fertilizerReport.krishi_bhaban'),
          style: 'krishi',
          alignment: 'center'
        },
        { text: reportHeadData.address, style: 'address', alignment: 'center' }
      ]
      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      const allRowsHead = [
        [
          { text: vm.$t('warehouse_config.fiscal_year') + ' : ' + (vm.search.fiscal_year_id ? (vm.currentLocale === 'en' ? vm.searchResult.fiscal_year : vm.searchResult.fiscal_year_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
          { text: vm.$t('pusti_mapping.month') + ' : ' + (vm.search.month ? (vm.currentLocale === 'en' ? vm.searchResult.month : vm.searchResult.month_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
          { text: vm.$t('fertilizerSales.allocationType') + ' : ' + (vm.search.allocation_type_id ? (vm.currentLocale === 'en' ? vm.searchResult.allocation_type : vm.searchResult.allocation_type_bn) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: '*',
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right' })
      let allRows = []
      const fertilizerTableColumns = []
      const fertilizerBlankColumns = []
      vm.fertilizerNameList.filter((fertilizerName, fertilizerIndex) => {
        const fertilizerTableColumn = {
          text: vm.currentLocale === 'bn' ? fertilizerName.text_bn : fertilizerName.text_en,
          style: 'th',
          alignment: 'right'
        }
        fertilizerTableColumns.push(fertilizerTableColumn)
        if (fertilizerIndex) {
          fertilizerBlankColumns.push({})
        }
      })
      // table header
      const headerRow1 = [
        { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('warehouse_config.fiscal_year'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('pusti_mapping.month'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('fertilizerSales.allocationType'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('dealer_panel.allocation_amount'), style: 'th', alignment: 'center', colSpan: vm.fertilizerNameList.length }
      ].concat(fertilizerBlankColumns, [
        { text: vm.$t('dealer_panel.sales_date'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('dealer_panel.sales_amount'), style: 'th', alignment: 'center', colSpan: vm.fertilizerNameList.length }
      ], fertilizerBlankColumns)
      const headerRow2 = [
        { },
        { },
        { },
        { }
      ].concat(fertilizerTableColumns, [{ }])
      vm.fertilizerNameList.filter((fertilizerName, fertilizerIndex) => {
        const fertilizerTableColumn = {
          text: vm.currentLocale === 'bn' ? fertilizerName.text_bn : fertilizerName.text_en,
          style: 'th',
          alignment: 'right'
        }
        headerRow2.push(fertilizerTableColumn)
      })
      allRows = [
        headerRow1,
        headerRow2
      ]
      // table body
      if (vm.reportData.length) {
        vm.reportData.filter((item, index) => {
          if (!item.sales_info.length) {
            const rowItem = [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: vm.currentLocale === 'en' ? item.fiscal_year : item.fiscal_year_bn, style: 'td', alignment: 'center' },
              { text: vm.currentLocale === 'en' ? item.month_name : item.month_name_bn, style: 'td', alignment: 'center' },
              { text: vm.currentLocale === 'en' ? item.allocation_type : item.allocation_type_bn, style: 'td', alignment: 'center' }
            ]
            vm.fertilizerNameList.filter(fertilizerName => {
              const fertilizerColumnValue = {
                text: item.allocation_fertilizer_info[fertilizerName.value] ? vm.$n(item.allocation_fertilizer_info[fertilizerName.value]) : '',
                style: 'td',
                alignment: 'right'
              }
              rowItem.push(fertilizerColumnValue)
            })
            rowItem.push({})
            vm.fertilizerNameList.filter(fertilizerName => {
              rowItem.push({})
            })
            allRows.push(rowItem)
          } else {
            item.sales_info.filter((salesItem, salesIndex) => {
              if (salesIndex === 0) {
                const rowItem = [
                 { text: vm.$n(index + 1), style: 'td', alignment: 'center', rowSpan: item.sales_info.length },
                 { text: vm.currentLocale === 'en' ? item.fiscal_year : item.fiscal_year_bn, style: 'td', alignment: 'center', rowSpan: item.sales_info.length },
                 { text: vm.currentLocale === 'en' ? item.month_name : item.month_name_bn, style: 'td', alignment: 'center', rowSpan: item.sales_info.length },
                 { text: vm.currentLocale === 'en' ? item.allocation_type : item.allocation_type_bn, style: 'td', alignment: 'center', rowSpan: item.sales_info.length }
               ]
                vm.fertilizerNameList.filter(fertilizerName => {
                 const fertilizerColumnValue = {
                   text: item.allocation_fertilizer_info[fertilizerName.value] ? vm.$n(item.allocation_fertilizer_info[fertilizerName.value]) : '',
                   style: 'td',
                   alignment: 'right',
                   rowSpan: item.sales_info.length
                 }
                 rowItem.push(fertilizerColumnValue)
                })
                rowItem.push({
                  text: vm.$options.filters.dateFormat(salesItem.sell_date),
                  style: 'td',
                  alignment: 'center'
                })
                vm.fertilizerNameList.filter(fertilizerName => {
                  const fertilizerColumnValue = {
                    text: salesItem.fertilizer_info[fertilizerName.value] ? vm.$n(salesItem.fertilizer_info[fertilizerName.value]) : '',
                    style: 'td',
                    alignment: 'right'
                  }
                  rowItem.push(fertilizerColumnValue)
                })
                allRows.push(rowItem)
              } else {
                const rowItem = [
                  { },
                  { },
                  { },
                  { }
                ]
                vm.fertilizerNameList.filter(fertilizerName => {
                  rowItem.push({})
                })
                rowItem.push({
                  text: vm.$options.filters.dateFormat(salesItem.sell_date),
                  style: 'td',
                  alignment: 'center'
                })
                vm.fertilizerNameList.filter(fertilizerName => {
                  const fertilizerColumnValue = {
                    text: salesItem.fertilizer_info[fertilizerName.value] ? vm.$n(salesItem.fertilizer_info[fertilizerName.value]) : '',
                    style: 'td',
                    alignment: 'right'
                  }
                  rowItem.push(fertilizerColumnValue)
                })
                allRows.push(rowItem)
              }
            })
          }
        })
      } else {
        const rowItem = [
          { text: vm.$t('globalTrans.noDataFound'), style: 'td', alignment: 'center', colSpan: vm.totalTableColumns }
        ]
        if (vm.fertilizerNameList.length) {
          let counter = 1
          while (counter <= (vm.totalTableColumns - 1)) {
            rowItem.push({ })
            counter++
          }
        }
        allRows.push(rowItem)
      }
      pdfContent.push({
        table: {
          headerRows: 2,
          widths: '*',
          body: allRows
        }
      })
      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 7,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: 6,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 10,
            margin: [0, 10, 0, 10]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, 1, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 2, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('fertilizer-sales-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
